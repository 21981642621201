export const amountOfRetries = 3;
export const reconnectTimeout = 1000;

export const autoHideSnackbar = 5000;

// eslint-disable-next-line no-magic-numbers
export const retryDelays = [0, 50, 100, 150];

export const urls = {
  quotes: '/v4/quotes',
  inboxes: '/inboxes',
  auth: '/auth',
  strategy: '/strategy',
  account: '/v2/account',
} as const;
