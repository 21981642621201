import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel,
} from '@microsoft/signalr';
import i18next from 'i18next';
import { enqueueSnackbar } from 'notistack';

import { emptyFn } from '@trader/constants';
import { devLoggerService } from '@trader/services';
import { appConfigUtils, urlHelpers } from '@trader/utils';

import {
  amountOfRetries,
  autoHideSnackbar,
  reconnectTimeout,
  retryDelays,
  urls,
} from './consts';
import {
  EConnectionHub,
  EConnectionSubscription,
  IConnectionMap,
  IParams,
  ISubscription,
  TConnectionCache,
  TRenderSnackBar,
  TSubscribe,
} from './types';

export { EConnectionHub, HubConnection, EConnectionSubscription };
export * from './consts';

const cache: TConnectionCache = (() => {
  const map = new Map<EConnectionHub, IConnectionMap>();

  for (const key of Object.keys(EConnectionHub)) {
    map.set(EConnectionHub[key], {
      key: EConnectionHub[key],
      hub: null,
      subscriptions: new Map(),
    });
  }
  return map;
})();

let retries = 0;
let shouldUseReconnection = true;

const renderSnackBar: TRenderSnackBar = ({ msg, variant }) => {
  enqueueSnackbar(msg, {
    variant,
    autoHideDuration: autoHideSnackbar,
    preventDuplicate: true,
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
  });
};

class WebSocketsService {
  private _subscribe: TSubscribe | typeof emptyFn;
  private _idToken: string;
  private readonly _url: IParams['url'];
  private readonly _hub: IParams['hub'];
  private readonly _subscription: IParams['subscription'];
  private readonly _refreshToken?: IParams['refreshToken'];

  constructor(params?: IParams) {
    this._url = params?.url || urls.quotes;
    this._hub = params?.hub || EConnectionHub.Quotes;
    this._subscription =
      params?.subscription || EConnectionSubscription.Watchlist;
    this._refreshToken = params?.refreshToken;
    this._subscribe = emptyFn;
    this._idToken = '';
  }

  private _getCurrentConnection() {
    return cache.get(this._hub) as IConnectionMap;
  }

  private _getCurrentHub() {
    const currentConnection = this._getCurrentConnection();
    return currentConnection?.hub;
  }

  private _getCurrentSubscriptions() {
    const currentConnection = this._getCurrentConnection();
    return currentConnection
      ? currentConnection.subscriptions
      : new Map<EConnectionSubscription, ISubscription>();
  }

  private _getCurrentSubscriptionsValues() {
    return this._getCurrentSubscriptions()?.size
      ? Array.from(this._getCurrentSubscriptions()?.values())
      : [];
  }

  private _createConnection() {
    if (!this._idToken) {
      return;
    }

    const baseUrl = urlHelpers.getBaseUrlWithApiType(
      import.meta.env.VITE_SIGNALR_URL,
      appConfigUtils.getCurrentApiType()
    );

    if (!this._getCurrentHub()) {
      try {
        cache.set(this._hub, {
          ...this._getCurrentConnection(),
          hub: new HubConnectionBuilder()
            .withUrl(baseUrl + this._url, {
              accessTokenFactory: () => this._idToken,
              skipNegotiation: true,
              transport: HttpTransportType.WebSockets,
            })
            .withAutomaticReconnect(retryDelays)
            .configureLogging(LogLevel.None)
            .build(),
        });
      } catch (e) {
        devLoggerService.log(`${this._hub} Build connection error`, e);

        renderSnackBar({
          msg: i18next.t('NOTIFICATIONS.CREATE_CONNECTION_FAILED'),
          variant: 'error',
        });
      }

      this._getCurrentHub()?.onreconnected(async () => {
        devLoggerService.log(`${this._hub} Reconnected successfully.`);
        console.log('onreconnected', this._idToken);

        if (!this._idToken) {
          return;
        }

        const subscriptions = this._getCurrentSubscriptionsValues();

        for (const subscription of subscriptions) {
          if (subscription.status !== 'subscribed') {
            await this.subscribe(subscription.start, subscription.key);
          }
        }
      });

      this._getCurrentHub()?.onreconnecting(error => {
        devLoggerService.log(`${this._hub} onreconnecting error`, error);
        console.log('onreconnecting', this._idToken);

        if (!this._idToken) {
          return;
        }

        const subscriptions = this._getCurrentSubscriptionsValues();

        subscriptions.forEach((subscription: ISubscription) => {
          this._getCurrentSubscriptions().set(subscription.key, {
            ...subscription,
            status: 'unsubscribed',
          });
        });
      });

      this._getCurrentHub()?.onclose(error => {
        devLoggerService.log(`${this._hub} Connection closed.`, error);
        console.log('onclose', shouldUseReconnection, this._idToken);
        if (shouldUseReconnection) {
          this._customReconnection();
        }
      });

      this._startConnection();
    } else {
      this._startConnection();
    }
  }

  private async _startConnection(isReconnecting = false) {
    if (!this._idToken) {
      return;
    }

    const hubState = this._getCurrentHub()?.state;

    try {
      hubState === HubConnectionState.Disconnected &&
        (await this._getCurrentHub()?.start());

      if (isReconnecting) {
        await this.subscribe(this._subscribe as TSubscribe);

        retries = 0;
      }
    } catch (err) {
      if (!this._getCurrentHub()) {
        return;
      }

      devLoggerService.log(`${this._hub} startConnection error`, err);

      this._customReconnection();
    }
  }

  private async _customReconnection() {
    devLoggerService.log(`${this._hub} customReconnection retried failed`);

    if (!this._idToken) {
      return;
    }

    if (retries < amountOfRetries) {
      setTimeout(() => this._startConnection(true), reconnectTimeout);
    }

    if (retries === amountOfRetries) {
      try {
        await this._refreshToken?.();
      } catch (err) {
        devLoggerService.log(`${this._hub} customReconnection failed`, err);
      }
    }

    retries += 1;
  }

  public async subscribe(
    subscribe?: TSubscribe,
    key?: EConnectionSubscription
  ) {
    subscribe && (this._subscribe = subscribe);

    const subscriptionKey = key || this._subscription;
    const subscriptionMap = this._getCurrentSubscriptions();

    subscriptionMap &&
      subscriptionMap.set(subscriptionKey, {
        key: subscriptionKey,
        status: 'subscribed',
        start: this._subscribe,
      });

    if (!this._idToken) {
      return;
    }

    const hub = this._getCurrentHub();

    try {
      if (hub?.state === HubConnectionState.Connected) {
        await this._subscribe(hub);
      } else {
        throw Error("Connection is not in the 'Connected' State.");
      }
    } catch (err) {
      if (err) {
        setTimeout(() => this.subscribe(subscribe), reconnectTimeout);
      }
    }
  }

  public async unsubscribe(unsubscribe: TSubscribe) {
    const hub = this._getCurrentHub();
    if (hub?.state === HubConnectionState.Connected) {
      await unsubscribe(hub);
    }
  }

  public async closeAll(shouldReconnect: boolean, idToken: string) {
    shouldUseReconnection = shouldReconnect;
    this._idToken = idToken;

    const allConnections: IConnectionMap[] = cache.size
      ? Array.from(cache.values())
      : [];

    for (const connection of allConnections) {
      await connection?.hub?.stop();
      cache.set(connection.key, { ...connection, hub: null });
    }
    if (!shouldReconnect) {
      cache.clear();
      retries = 0;
    }
  }

  public start(idToken: string) {
    this._idToken = idToken;
    this._createConnection();
  }
}

export function webSocketsService(params: IParams): WebSocketsService;
export function webSocketsService(): WebSocketsService;

export function webSocketsService(params?: IParams) {
  return new WebSocketsService(params);
}
