import { useEffect, useMemo } from 'react';

import {
  EConnectionHub,
  EConnectionSubscription,
  webSocketsService,
  urls,
} from '@trader/services';
import { useMst } from '@trader/store';

export const useStartConnection = (
  hub: EConnectionHub,
  subscription: EConnectionSubscription,
  url: keyof typeof urls
) => {
  const store = useMst();

  const idToken = store.auth.tokens.idToken;

  const isAppDataLoaded =
    store.pages.trading.getPrefetchInformationAsync.hasEverBeenRun;

  const connection = useMemo(
    () =>
      webSocketsService({
        hub,
        subscription,
        url: urls[url],
        refreshToken: async () => {
          await store.auth.authRefreshTokenAsync.run();
        },
      }),
    []
  );

  useEffect(() => {
    connection.start(idToken);
  }, [idToken]);

  return { connection, isAppDataLoaded };
};
